@import "../rgbstyle/rgbstyle-mixins";
@import "../rgbstyle/rgbstyle-variables";

.el-home-institucional {
    padding: 40px 0 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include desktop {
        padding: 0px;
    }

    .wrapper-img-texto {
        @include desktop {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .texto {

            @include desktop {
                padding: 90px 0 90px 50px;
                position: relative;
                width: 50%;
                float: right;
                display: block;
                margin: 0 0 0 50%;
                &::after {
                    content: '';
                    position: absolute;
                    top: -30px;
                    bottom: 0;
                    right: 625px;
                    width: 910px;
                    background: url(../../imagens/home/bg-institucional.png) center;
                    background-size: cover;
                }
            }

            @include desktop-hd {
                padding: 90px 0 90px 100px;
            }

            .titulo {
                font-size: 20px;
                text-align: center;
                font-weight: bold;
                line-height: 1.2;
                margin-bottom: 20px;
                @include tablet {
                    font-size: 30px;
                    text-align: left;
                }
            }

            .texto-editavel {
                margin-bottom: 20px;
                font-size: 16px;

                h3 {
                    text-transform: uppercase;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 20px;
                }
            }

            .btn-mais {
                display: inline-block;
                padding: 10px 35px;
                border: 1px solid #8f8f8f;

                &:hover {
                    color: #ffffff;
                    background-color: $color_destaque;
                }
            }
        }

    }

    &.imagem-left {
        .img {
            @include desktop {
                float: left;
                width: 50%;
                margin-right: 4%;

                order: 0;
            }
        }
    }

    &.imagem-right {
        .img {
            @include desktop {
                float: right;
                width: 50%;
                margin-left: 4%;

                order: 1;
            }
        }
    }
}